input[type=range] {
    height: 35px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #6C757D;
    background: #F8F8F8;
    border-radius: 50px;
    border: 1px solid #6C757D;
}

input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 28px;
    width: 50px;
    border-radius: 50px;
    background: #0D6EFD;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #F8F8F8;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #6C757D;
    background: #F8F8F8;
    border-radius: 50px;
    border: 1px solid #6C757D;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 28px;
    width: 50px;
    border-radius: 50px;
    background: #0D6EFD;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #F8F8F8;
    border: 1px solid #6C757D;
    border-radius: 100px;
    box-shadow: 0px 0px 0px #6C757D;
}

input[type=range]::-ms-fill-upper {
    background: #F8F8F8;
    border: 1px solid #6C757D;
    border-radius: 100px;
    box-shadow: 0px 0px 0px #6C757D;
}

input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 28px;
    width: 50px;
    border-radius: 50px;
    background: #0D6EFD;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #F8F8F8;
}

input[type=range]:focus::-ms-fill-upper {
    background: #F8F8F8;
}

.range-container {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    float: left;
    height: 50px;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #333;
    user-select: none;
    pointer-events: none;
}

/* Small and thin version */
input[type=range].small {
    height: 20px;
    /* Reduced height */
    width: 100%;
    /* Adjust width as needed */
    -webkit-appearance: none;
    margin: 5px 0;
    /* Smaller margin */
}

input[type=range].small:focus {
    outline: none;
}

input[type=range].small::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    /* Thinner track */
    cursor: pointer;
    box-shadow: 0px 0px 0px #6C757D;
    background: #F8F8F8;
    border-radius: 25px;
    /* Smaller radius for a thin look */
    border: 1px solid #6C757D;
}

input[type=range].small::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 20px;
    /* Smaller thumb height */
    width: 30px;
    /* Smaller thumb width */
    border-radius: 20px;
    background: #0D6EFD;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -1px;
    /* Adjust positioning */
}

input[type=range].small:focus::-webkit-slider-runnable-track {
    background: #F8F8F8;
}

input[type=range].small::-moz-range-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #6C757D;
    background: #F8F8F8;
    border-radius: 20px;
    border: 1px solid #6C757D;
}

input[type=range].small::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 20px;
    width: 30px;
    border-radius: 20px;
    background: #0D6EFD;
    cursor: pointer;
}

input[type=range].small::-ms-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range].small::-ms-fill-lower {
    background: #F8F8F8;
    border: 1px solid #6C757D;
    border-radius: 25px;
}

input[type=range].small::-ms-fill-upper {
    background: #F8F8F8;
    border: 1px solid #6C757D;
    border-radius: 25px;
}

input[type=range].small::-ms-thumb {
    margin-top: -4px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 15px;
    width: 30px;
    border-radius: 20px;
    background: #0D6EFD;
    cursor: pointer;
}

input[type=range].small:focus::-ms-fill-lower {
    background: #F8F8F8;
}

input[type=range].small:focus::-ms-fill-upper {
    background: #F8F8F8;
}

/* Adjust the container for the smaller version */
.range-container.small {
    width: 100%;
    max-width: 200px;
    /* Smaller container */
    height: 25px;
    /* Adjust height for smaller size */
}

.overlay-text.small {
    font-size: 16px;
    /* Smaller font size for the text overlay */
}