body {
    background-color: #fafafa !important;
    font-family: 'Maven Pro', sans-serif;
}

.logo {
    max-width: 100px;
    max-height: 100px;
}

canvas {
    display: block !important;
}
