.round-selected .form-check-label {
    background-color: #0A58CA;
    color: white;

    width: 30px;
    /* Set a fixed width for all labels */
    height: 30px;
    /* Set a fixed height for all labels */
    display: inline-block;
    /* Ensure labels take up the specified width and height */
    border-radius: 50%;
    /* Create a circular shape */
    text-align: center;
    /* Center text within labels */
    line-height: 30px;
    /* Center vertically */
    padding: 0;
    /* Remove padding */
    cursor: pointer;
}

.round .form-check-label {
    background-color: #DEE2E6;

    width: 30px;
    /* Set a fixed width for all labels */
    height: 30px;
    /* Set a fixed height for all labels */
    display: inline-block;
    /* Ensure labels take up the specified width and height */
    border-radius: 50%;
    /* Create a circular shape */
    text-align: center;
    /* Center text within labels */
    line-height: 30px;
    /* Center vertically */
    padding: 0;
    /* Remove padding */
    cursor: pointer;
}

.round-selected input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
}

.round input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
}