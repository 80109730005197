.rbt-token {
    /* Bootstrap primary button style */
    background-color: #007bff;
    color: white;
}

.rbt-token-active {
    /* Bootstrap danger button style */
    background-color: #dc3545;
    color: white;
}

.container {
    position: relative;
}

.backgroundImage {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    pointer-events: none;
    cursor: pointer;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    transition: .5s ease;
    opacity: 0;
}

.container:hover .overlay {
    opacity: 1;
}