.breadcrumb {
    background-color: white !important;
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    /* border: 1px solid rgba(0,0,0,.125) !important; */
    /* padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem; */
}

.breadcrumb > li + li:before {
    content: ">" !important;
}
